import { Controller } from 'stimulus';
import ahoy from 'ahoy.js';

export default class extends Controller {
  static values = {
    name: String,
    params: Object
  }

  track() {
    if (this.name != null) {
      ahoy.track(this.name, this.params);
    }
  }

  get name() {
    return this.data.get('name-value');
  }

  get params() {
    return JSON.parse(this.data.get('params-value'));
  }
}

