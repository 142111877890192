import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['value'];

  updateCarrier(event) {
    this.stimulate(
      'CarrierAccountCredentialsReflex#update_carrier',
      this.valueTarget.value
    );
  }
}
