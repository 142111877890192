import ApplicationController from './application_controller'
import { animateCSS } from '~/animation';
import { debounce, trim } from 'lodash';
import Popper from 'popper.js';

export default class extends ApplicationController {
  static targets = ['query'];

  connect () {
    super.connect()
    this.search = debounce(this._search, 300).bind(this);
  }

  create(e) {
    e.preventDefault();
    const tag = e.currentTarget.dataset['tag'] || this.queryTarget.value;
    this.stimulate('TagsReflex#create', tag);
  }

  add(e) {
    e.preventDefault();
    const tag = e.currentTarget.dataset['tag'] || this.queryTarget.value;
    this.stimulate('TagsReflex#add', tag);
  }

  destroy(e) {
    e.preventDefault();
    this.stimulate('TagsReflex#destroy', e.currentTarget.dataset['tag']);
  }

  changed(e) {
    this.element.dataset['query'] = this.queryTarget.value;
  }

  clear() {
    this.queryTarget.value = '';
  }

  _search(e) {
    e.preventDefault();
    this.stimulate('TagsReflex#search');
  }

  get queryEmpty() {
    return trim(this.queryTarget.value.length) == 0;
  }
}
