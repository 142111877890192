import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import _ from 'lodash';
import $ from 'jquery';

export default class extends Controller {
    static targets = [ 'input', 'sheetContainer' ];

    initialize() {
        this.regexpFilter = /^q\[|^utf8/;
        this.q = _.debounce(this.q, 500);
        this.qUrl = _.debounce(this.qUrl, 500);
    }

    q() {
        const form = this.form();
        Rails.fire(form, 'submit');
    }

    qUrl(event) {
        const form = $(this.form());
        const params = this.filterKeys(form);
        const url = `${form.attr('action')}?${$.param(params)}`;
        let opts = { url: decodeURIComponent(url) };
        Page.refresh(opts);
    }

    onlyKeys(event) {
        let keys = this.data.get('onlyKeys');
        if(!this.isEventFromSheet(event)) {
            keys = `${keys} filter-sheet`;
        }

        return keys && keys.split(' ');
    }

    isEventFromSheet(event) {
        return this.hasSheetContainerTarget && (
            $.contains(this.sheetContainerTarget, event.target) || event.target.dataset.sheet
        );
    }

    filterKeys(form) {
        const paramsFilter = [];
        const params = form.serializeArray().filter((param) => {
            const key = `${param.name}${param.value}`;
            const filterName = this.regexpFilter.test(param.name);
            if (filterName && param.value !== '' && !paramsFilter.includes(key)) {
                paramsFilter.push(key);
                return true;
            }
        });

        return params;
    }

    form() {
        return this.element.getElementsByTagName('form')[0];
    }
}