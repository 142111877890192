import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['tgButton'];

  checkbox(event) {
    const data = this.eventData(event);
    $(`[name='${data.name}'][value='${data.value}']`).
      prop('checked', data.checked);
  }

  eventData(event) {
    const target = $(event.target);
    const checked = target.prop('checked');
    const name = target.attr('name');
    const value = target.attr('value');
    return { checked, name, value };
  }

  removeHiddenDateFromTag(event) {
    const data = this.eventData(event);
    data.name.split('_to_').forEach((name) => {
      $(`#filter-tags [name='q[${name}]'][type='hidden']`).remove();
    });
  }

  removeHiddenField(event) {
    const data = this.eventData(event);
    $(`[name='${data.name}'][type='hidden'][value='${data.value}']`).remove();
  }

  triggerCloseTag(event) {
    const groupKey = event.currentTarget.dataset.group;
    const element = $(`#filter-tags .${groupKey} button`);
    element.attr('data-sheet', true).trigger('click');
  }

  triggerTgButton() {
    this.tgButtonTargets.forEach((button) => {
      button.click();
    });
  }

  uncheckAll(event) {
    const groupKey = event.currentTarget.dataset.group;
    $(`[name='q[${groupKey}][]']`).prop('checked', false);
  }
}
