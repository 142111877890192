import ApplicationController from './application_controller';

export default class extends ApplicationController {
  dispatch(event) {
    if (this.keyValue && event.key !== this.keyValue) return;

    this.dispatchEvent(this.eventValue);
  }

  get keyValue() {
   return this.data.get('key-value');
  }

  get eventValue() {
   return this.data.get('event-value');
  }
}
