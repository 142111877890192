import Flatpickr from 'stimulus-flatpickr';
import $ from 'jquery';
import moment from 'moment';
import { Spanish } from 'flatpickr/dist/l10n/es.js';

export default class extends Flatpickr {
  initialize() {
    super.initialize();
    this.config = {
      ...this.config,
      altFormat: this.altFormat,
      altInput: this.altFormat && true,
      locale: this.locale
    };

    document.addEventListener(
      "stimulus-reflex:finalize",
      this.refreshPicker.bind(this),
      true
    );
  }

  connect() {
    const min = this.minField().val();
    const max = this.maxField().val();
    const valuesNotEmpty = this.notEmpty(min) && this.notEmpty(max);
    if (valuesNotEmpty) {
      this.config = {
        ...this.config,
        defaultDate: [min, max]
      };
    }

    super.connect();

    if (!valuesNotEmpty) {
      this.fp.setDate(null);
    } else if (valuesNotEmpty && !this.altFormat) {
      this.inputTarget.value = min + ' to ' + max;
    }
  }

  refreshPicker() {
    this.disconnect();
    this.connect();
  }

  valueUpdate(date, dateStr, instance) {
    // Bug with noCalendar
    if (this.config.noCalendar && this.config.enableTime) {
      this.change(date, dateStr, instance);
    }
  }

  change(selectedDates, dateStr, instance) {
    if (this.isInRangeMode() && selectedDates.length === 2) {
      this.minField().val(this.formatDate(selectedDates[0]));
      this.maxField().val(this.formatDate(selectedDates[1]));
      if (this.element.tagName === 'DIV' || this.data.get('dispatch')) {
        this.dispatchChange();
      }
    } else if (!this.isInRangeMode() && selectedDates[0]) {
      this.singleField().val(this.formatDate(selectedDates[0]));
      if (this.element.tagName === 'DIV' || this.data.get('dispatch')) {
        this.dispatchChange();
      }
    }
  }

  dispatchChange() {
    const event = document.createEvent('Event');
    event.initEvent(this.eventName, true, true);
    this.element.dispatchEvent(event);
  }

  formatDate(date) {
    if (this.config.enableTime) {
      return moment(date).utc().format();
    }
    return moment(date).format('YYYY-MM-DD');
  }

  isInRangeMode() {
    return this.data.get('mode') === 'range';
  }

  form () {
    return $(this.element).closest('.date-range-form');
  }

  minField() {
    return $(this.form()).find('.js-min-field');
  }

  maxField() {
    return $(this.form()).find('.js-max-field');
  }

  singleField() {
    return $(this.form()).find('.js-single-field');
  }

  notEmpty(value) {
    return value !== null && value !== '';
  }

  get altFormat() {
    return this.data.get('altFormat');
  }

  get locale() {
    return this.data.get('locale') === 'es' ? Spanish : null;
  }

  get eventName() {
    return this.data.get('eventName') || 'update'
  }
}
