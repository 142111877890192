import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['skeleton', 'list']

  connect() {
    this.loadingElement = this.loadingElementSkeeleton();
    this.maybeFixHeight();
  }

  loadingElementSkeeleton() {
    if (this.hasSkeletonTarget) {
      return $(this.skeletonTarget);
    }
    const id = this.data.get('id');
    return $(`#${id}`);
  }

  maybeFixHeight() {
    if (this.hasListTarget) {
      this.loadingElement.height(`${this.listTarget.offsetHeight}px`);
    }
  }

  show() {
    this.loadingElement.siblings().hide();
    this.loadingElement.show();
  }

  refreshElementAndShow() {
    this.connect();
    this.show();
  }
}
