import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values = {
    connected: Boolean
  };

  /*
  connect() {
    super.connect();
    this.connectedValue = this.isActionCableConnectionOpen();
    setTimeout(() => this.showIfDisconnected(), 5000);
  }
  */

  showIfDisconnected() {
    if (!this.isActionCableConnectionOpen()) {
      this.cableDisconnected();
    }
  }

  cableConnected() {
    this.connectedValue = true;
    this.hide();
  }

  cableDisconnected() {
    this.connectedValue = false;
    this.show();
  }

  show() {
    this.element.classList.remove("hidden");
  }

  hide() {
    this.element.classList.add("hidden");
  }
}