import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = [ 'value' ];

  connect() {
    this.selectize = $(this.valueTarget).selectize({
      dropdownParent: 'body',
      allowEmptyOption: !this.includeBlankValue,
      maxItems: this.multipleItemsValue ? null : 1,
      create: this.createValue,
      createOnBlur: this.createValue,
      render: {
        option_create: (d, e) => this.renderOptionCreate(d, e),
      },
      placeholder: this.placeholderValue
    });
    this.selectize.on('change', () => {
      this.valueTarget.dispatchEvent(new CustomEvent('select:change'));
      $(this.valueTarget).trigger('click');
    });
  }

  renderOptionCreate(data, escape) {
    return `<div class="create">${this.optionCreateLabel} <strong>${escape(data.input)}</strong>&hellip;</div>`;
  }

  get optionCreateLabel() {
    return this.optionCreateLabelValue || 'Agregar';
  }

  get value() {
    return this.valueTarget.value;
  }

  get multipleItemsValue() {
    return this.data.get('multiple-items-value') == 'true';
  }

  get includeBlankValue() {
    return this.data.get('include-blank-value') == 'true';
  }

  get createValue() {
    return this.data.get('create-value') == 'true';
  }

  get placeholderValue() {
    return this.data.get('placeholder-value');
  }

  get optionCreateLabelValue() {
    return this.data.get('option-create-label-value');
  }
}
