import 'core-js/es6';
import 'promise-polyfill/src/polyfill';
import '@stimulus/polyfills';

require('@rails/ujs').start();
require('@rails/activestorage').start();

import { Trix } from 'trix';
require('jquery');
import 'selectize';

require( '../javascripts/controllers');
require('../javascripts/ahoy')

require('chartkick');
require('chart.js');