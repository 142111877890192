import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  remove() {
    this.element.parentNode.removeChild(this.element);
  }

  uncheck() {
    this.touchList.forEach((selector) => {
      const multipleSelector = $(`[name='q[${selector}][]']`);
      if (multipleSelector.length > 0) {
        // Checkbox for multiple values
        multipleSelector.prop('checked', false);
      } else {
        // Checkbox for single value
        $(`[name='q[${selector}]']`).prop('checked', false);
      }

      $(`[name='q[${selector}]'][type='hidden']`).prop('value', '');
    });
  }

  get touchList() {
    return (this.data.get('touchList') || '').split(' ');
  }
}
