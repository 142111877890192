// this controller allows to update the URL even if only a frame content changed.
// this behaviour is currently not supported by turbo. see https://github.com/hotwired/turbo/issues/50
// taken from: https://gist.github.com/Intrepidd/bb1ffc5944a5c1ec3a9f5582753c4b67
// other implementation: https://gist.github.com/Intrepidd/ac68cb7dfd17d422374807efb6bf2f42

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
  }

  mutate(entries) {
    entries.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'src') {
        const src = this.element.getAttribute('src');
        if (src) {
          navigator.history.push(new URL(src));
        }
      }
    });
  }
}