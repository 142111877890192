import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['option', 'allOption', 'button']

  connect() {
    if (this.hasAllOptionTarget) {
      this.allOptionTarget.checked = this.hasAllChecked() || this.hasAllUnchecked();
      if (this.allOptionTarget.checked) {
        this.markAllWithValue(true);
      }
      this.updateButtonText();
    }
  }

  countSelected() {
    return this.
      optionTargets.
      reduce((sum, option) => option.checked ? ++sum : sum, 0);
  }

  filterCheckeds() {
    return this.optionTargets.filter((option) => {
      return option.checked;
    });
  }

  hasAllChecked() {
    return this.filterCheckeds().length === this.optionTargets.length;
  }

  hasAllUnchecked() {
    return this.filterCheckeds().length === 0;
  }

  mark(event) {
    if (this.hasAllOptionTarget) {
      if (event.target.checked) {
        this.allOptionTarget.checked = this.hasAllChecked();
      } else {
        this.allOptionTarget.checked = false;
      }
    }
    this.
      optionTargets.
      filter((option) =>  event.target.id == option.id ).
      forEach((option) => { option.checked = event.target.checked })
  }

  markAll(event) {
    this.markAllWithValue(event.target.checked);
  }

  markAllWithValue(value) {
    this.optionTargets.forEach((option) => {
      option.checked = value;
    });
  }

  updateButtonText() {
    const target = $(this.buttonTarget);
    if (this.hasOptionTarget) {
      target.removeClass('Polaris-Button--disabled');
    }
    if (this.textAvailable) {
      this.updateTargetText(target);
    }
  }

  updateTargetText(target) {
    const textElement = target.find('.Polaris-Button__Text');
    if (this.allOptionTarget.checked) {
      textElement.text(this.buttonAllText);
    } else {
      const text = this.buttonNText.replace('N', this.countSelected());
      textElement.text(text);
    }
  }

  get buttonAllText() {
    return this.data.get('buttonAllText');
  }

  get buttonNText() {
    return this.data.get('buttonNText');
  }

  get textAvailable() {
    return this.buttonAllText && this.buttonNText;
  }
}
